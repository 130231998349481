// Generated by Framer (716dd6f)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, Link, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const enabledGestures = {BwdXlq6zL: {hover: true}};

const cycleOrder = ["BwdXlq6zL"];

const variantClassNames = {BwdXlq6zL: "framer-v-1gb8rlu"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};


function toResponsiveImage_194x2gw(value) {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const BASE62 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz"

function useRandomID(){const ref = React.useRef(null);
if (ref.current === null) {
ref.current = Array(5).fill(0).map(() => BASE62[Math.floor(Math.random() * BASE62.length)]).join("");
}
return ref.current;}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; link?: string; image?: {src: string; srcSet?: string} }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style: externalStyle = {}, className, width, height, layoutId, variant: outerVariant = "BwdXlq6zL", link: gd4KAE8O4, image: IcbPEHkNA = {src: new URL("assets/512/QcQC65d4V7MBLRP91RzIksXp0.jpg", import.meta.url).href, srcSet: `${new URL("assets/512/QcQC65d4V7MBLRP91RzIksXp0.jpg", import.meta.url).href} 512w, ${new URL("assets/QcQC65d4V7MBLRP91RzIksXp0.jpg", import.meta.url).href} 750w`}, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "BwdXlq6zL", enabledGestures, transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = useRandomID()

const { pointerEvents, ...style } = externalStyle

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div data-framer-generated initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-oE0qL", classNames)} style={{display: "contents", pointerEvents: pointerEvents ?? "auto"}}>
<motion.div {...restProps} className={cx("framer-1gb8rlu", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"BwdXlq6zL"} ref={ref} style={{...style}} transition={transition} {...addPropertyOverrides({"BwdXlq6zL-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><Link href={gd4KAE8O4}><Image as={"a"} background={{alt: "", fit: "fit", intrinsicHeight: 750, intrinsicWidth: 750, pixelHeight: 750, pixelWidth: 750, ...toResponsiveImage_194x2gw(IcbPEHkNA)}} className={"framer-o43kmp framer-1q00qvd"} data-framer-name={"image 20"} layoutDependency={layoutDependency} layoutId={"TEAXwr1an"} style={{filter: "grayscale(1)", WebkitFilter: "grayscale(1)"}} transition={transition} variants={{"BwdXlq6zL-hover": {filter: "grayscale(0)", WebkitFilter: "grayscale(0)"}}}/></Link></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-oE0qL [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-oE0qL * { box-sizing: border-box; }", ".framer-oE0qL .framer-1q00qvd { display: block; }", ".framer-oE0qL .framer-1gb8rlu { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 510px; justify-content: center; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; width: 510px; }", ".framer-oE0qL .framer-o43kmp { aspect-ratio: 1 / 1; flex: 1 0 0px; height: var(--framer-aspect-ratio-supported, 510px); overflow: hidden; position: relative; text-decoration: none; width: 1px; }", ".framer-oE0qL .framer-v-1gb8rlu .framer-1gb8rlu { cursor: pointer; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-oE0qL .framer-1gb8rlu { gap: 0px; } .framer-oE0qL .framer-1gb8rlu > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-oE0qL .framer-1gb8rlu > :first-child { margin-left: 0px; } .framer-oE0qL .framer-1gb8rlu > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 510
 * @framerIntrinsicWidth 510
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"hdw6f3mMF":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"gd4KAE8O4":"link","IcbPEHkNA":"image"}
 */
const Framerve6Gmp8Iw: React.ComponentType<Props> = withCSS(Component, css) as typeof Component;
export default Framerve6Gmp8Iw;

Framerve6Gmp8Iw.displayName = "gender-divide";

Framerve6Gmp8Iw.defaultProps = {height: 510, width: 510};

addPropertyControls(Framerve6Gmp8Iw, {gd4KAE8O4: {title: "Link", type: ControlType.Link}, IcbPEHkNA: {__defaultAssetReference: "data:framer/asset-reference,QcQC65d4V7MBLRP91RzIksXp0.jpg?originalFilename=image.jpg&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(Framerve6Gmp8Iw, [])